import React, { useState } from "react";
import Wrapper from "./Wrapper";
import Input from "../Input";
import Button from "../Button";
import { API } from "../../../config/api";
import { emailValidate } from "../../../utils/validator";
import Alert from "./Alert";

export default function ForgotPwd({
  onClose,
  setSession,
  username,
  setUsername,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState("");

  const sendCode = async () => {
    if (!username || !email) {
      setError("All fields required!");
    } else if (!emailValidate(email)) {
      setError("inValid e-mail address");
    } else {
      setLoading(true);
      setError(null);

      const data = {
        email: email,
        username: username,
      };

      try {
        const res = await API.post(`/forgot-password`, data);

        setSession(res.data?.sessionhash);
        setLoading(false);
        setAlert(res.data?.status);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data) {
          setError(err.response.data?.error);
        } else {
          setError("Something went wrong, try again!");
        }
      }
    }
  };
  const closePopup = () => {
    setAlert("");
    onClose();
  };

  return (
    <Wrapper onClose={onClose}>
      <div className="pt-[56px] px-5 sm:px-[76px] pb-[70px]">
        <p className="font-normal text-2xl sm:text-[32px] text-white leading-[48px]">
          Forgot password
        </p>
        <p className="font-normal mt-[54px] max-w-[441px] tracking-[0.135em] text-xs sm:text-sm text-[#606164] leading-[21px]">
          A reset password code will be sent to your email address. Enter the
          registered emai & username below.
        </p>
        <div className="mt-[25px] max-w-[326px] mb-[26px]">
          <Input
            placeholder={"Enter username"}
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            placeholder={"Enter email"}
            type="email"
            value={email}
            mt="mt-[9px]"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {error !== null && (
          <span className="text-red-700 opacity-80 mb-2  block font-bold text-sm">
            {error}
          </span>
        )}
        <Button
          text={loading ? "Loading..." : "Send"}
          width="w-[170px]"
          disable={loading}
          onClick={sendCode}
        />
      </div>
      {alert !== "" && <Alert message={alert} onClose={closePopup} />}
    </Wrapper>
  );
}
