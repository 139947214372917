import React, { useState } from "react";
import Wrapper from "./Wrapper";
import Input from "../Input";
import Button from "../Button";
import { API } from "../../../config/api";

export default function TwoFactorAuth({
  state,
  check,
  session,
  onClose,
  setSession,
  navigate,
  saveUserData,
  setUser,
  setIsLoggedIn,
}) {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState("");

  const getNewCode = async () => {
    setSending(true);
    try {
      const res = await API.post("/login", state);
      setSession(res.data?.sessionhash);
      setSending(false);
    } catch (err) {
      setSending(false);
      if (err?.response?.data) {
        setError(err?.response?.data);
      } else {
        setError("Something went wrong, please try again!");
      }
    }
  };

  const verifyCode = async () => {
    if (!code) {
      setError("6-Digits code required");
    } else {
      setLoading(true);
      setError(null);
      const data = {
        ...state,
        code2fa: code,
        sessionhash: session,
      };

      try {
        const res = await API.post("/login", data);
        localStorage.setItem("_id_", res.data.token);

        const userRes = await API.get(`/user/${res.data.userId}`);

        saveUserData(userRes.data);
        setUser(userRes.data);
        setIsLoggedIn(true);
        if (check) {
          localStorage.setItem("@remember", "yes");
        } else {
          sessionStorage.setItem("_save", "no");
        }
        navigate("/");
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data) {
          setError(err.response.data?.error);
        } else {
          setError("Something went wrong, try again!");
        }
      }
    }
  };

  return (
    <Wrapper onClose={onClose}>
      <div className="pt-[56px] px-5 sm:px-[76px] pb-[70px]">
        <p className="font-normal text-2xl sm:text-[32px] text-white leading-[48px]">
          Two Way Authentication
        </p>
        <p className="font-normal mt-[54px] max-w-[441px] tracking-[0.135em] text-xs sm:text-sm text-[#606164] leading-[21px]">
          A verification code has been sent to your email. If you did not
          recieve it, please check your spam/junk folder. Otherwise{" "}
          <span className="cursor-pointer underline" onClick={getNewCode}>
            {sending ? "Sending..." : "Get new code"}
          </span>
        </p>
        <div className="mt-[25px] max-w-[326px] mb-[26px]">
          <Input
            placeholder={"6-Digits code"}
            type="text"
            value={code}
            mt="mt-[9px]"
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        {error !== null && (
          <span className="text-red-700 opacity-80 mb-2  block font-bold text-sm">
            {error}
          </span>
        )}
        <Button
          text={loading ? "Loading..." : "Verify"}
          width="w-[170px]"
          disable={loading}
          onClick={verifyCode}
        />
      </div>
    </Wrapper>
  );
}
