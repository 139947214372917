import React, { useState, useEffect, useContext } from "react";
import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Input from "../components/common/Input";
import Button from "../components/common/Button";
import Checkbox from "../components/common/Checkbox";
import { API } from "../config/api";
import { AuthContext } from "../context/authContext";
import { OpenEye, CloseEye } from "../components/svgs";
import { saveUserData, isLoggedIn, getUserData } from "../utils/userData";
import ForgotPwd from "../components/common/popup/forgotPwd";
import { ChangePassword } from "../components/profile/popup";
import TwoFactorAuth from "../components/common/popup/twoFactorAuth";

export default function SignIn() {
  const { setUser, setIsLoggedIn } = useContext(AuthContext);
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [session, setSession] = useState("");
  const [forgotPopup, setForgotPopup] = useState(false);
  const [pwdPopup, setPwdPopup] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [check, setChecked] = useState(true);
  const [checkAuth, setCheckAuth] = useState(true);
  const [username, setUsername] = useState("");
  const [twoFactor, setTwoFactor] = useState(false);

  useEffect(() => {
    if (isLoggedIn() && getUserData()) {
      navigate("/");
    } else {
      setCheckAuth(false);
    }
  }, []);

  const handleChange = (evt) => {
    setError(null);
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };
  const toggleChecked = () => {
    setChecked(!check);
  };

  const handleLogin = async () => {
    const { username, password } = state;
    if (username === "" || password === "") {
      setError("username & password are required");
    } else {
      setError(null);
      setLoading(true);

      const data = {
        username: username.toLowerCase(),
        password: password,
      };

      try {
        const res = await API.post("/login", data);

        if (res.data?.sessionhash) {
          setLoading(false);
          setSession(res.data?.sessionhash);
          setTwoFactor(true);
        } else {
          localStorage.setItem("_id_", res.data.token);
          const userRes = await API.get(`/user/${res.data.userId}`);

          saveUserData(userRes.data);
          setUser(userRes.data);
          setIsLoggedIn(true);
          if (check) {
            localStorage.setItem("@remember", "yes");
          } else {
            sessionStorage.setItem("_save", "no");
          }
          navigate("/");
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        if (err?.response?.data) {
          setError("username or password is incorrect!");
        } else {
          setError("Something went wrong! please try again");
        }
      }
    }
  };

  const toggleForgotPwd = () => {
    if (session) {
      setPwdPopup(!pwdPopup);
    }
    setForgotPopup(!forgotPopup);
  };

  const toggleChangePwd = () => {
    setSession("");
    setUsername("");
    setPwdPopup(!pwdPopup);
  };

  const closeTwoFactor = () => {
    setTwoFactor(false);
    setSession("");
  };

  if (checkAuth) return <div></div>;

  return (
    <Layout>
      <div className="mb-[200px] relative">
        <div className="absolute hidden md:block top-0 right-0 bottom-0 left-0 -z-10 min-h-[313px] wave">
          <StaticImage
            src="../images/hero.webp"
            alt="contact-us"
            placeholder="blurred"
          />
        </div>
        <div className="max-w-[1040px] mx-5 xl:mx-auto border pb-[70px] pt-[52px] pl-5 pr-5 sm:pl-[68px] sm:pr-[68px] md:pr-0 mt-[26px] bg-[#16171B] border-white/30 rounded-[28px]">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-[60%] lg:w-full">
              <StaticImage src="../images/logo.png" alt="logo" height={54} />
              <p className="mt-[23px] text-[20px] leading-[30px] font-normal tracking-[0.135em] text-white">
                Returning User
              </p>
              <p className="mt-[10px] text-sm leading-[21px] font-normal tracking-[0.135em] text-white/30">
                Login to access your account.
              </p>
              {error !== null && (
                <span className="text-red-700 opacity-80 -mb-5   block font-bold text-sm">
                  {error}
                </span>
              )}
              <div className="mt-[27.12px]">
                <Input
                  placeholder="Username"
                  type="text"
                  name={"username"}
                  width="w-full sm:w-[326px]"
                  value={state.username}
                  onChange={handleChange}
                />
                <br />
                <div className="flex items-center w-full sm:w-[327px] mt-[9px] text-[13px] px-[26px] leading-[20px] font-bold tracking-[0.135em] text-white  h-[50px] bg-alto/5 rounded-[25px]">
                  <input
                    className="bg-transparent tracking-[0.135em] w-full sm:w-[327px] outline-none border-none"
                    type={showPwd ? "text" : "password"}
                    value={state.password}
                    onChange={handleChange}
                    name="password"
                    placeholder="Password"
                  />
                  <span onClick={() => setShowPwd(!showPwd)}>
                    {showPwd ? <CloseEye /> : <OpenEye />}
                  </span>
                </div>

                <div className="flex items-center justify-between w-full sm:w-[327px]">
                  <div className="flex items-center mt-[17px] ml-4 mb-[13px]">
                    <Checkbox
                      checked={check}
                      onChange={toggleChecked}
                      width="w-4"
                      height="h-4"
                      iconWidth="14"
                      iconHeight="12"
                    />
                    {/* <input
                    type="checkbox"
                    className="appearance-none h-[14px] w-[14px] border border-alto rounded-sm bg-transparent checked:bg-alto checked:border-alto focus:outline-none transition duration-200 cursor-pointer"
                  /> */}
                    <p className="text-xs leading-[21px] ml-[9px] font-normal tracking-[0.135em] text-white">
                      Remember Me
                    </p>
                  </div>
                  <span
                    onClick={toggleForgotPwd}
                    className=" cursor-pointer mt-[4px] text-xs leading-[21px] font-normal tracking-[0.135em] text-white/30"
                  >
                    Forgot password
                  </span>
                </div>
                <Button
                  text={loading ? "Loading..." : "LOGIN"}
                  width="w-[171px]"
                  clickAble={true}
                  disable={loading}
                  onClick={handleLogin}
                />
              </div>
            </div>
            {/* Middle Line */}
            {/* <div className="h-[1px] w-full md:w-[1px] md:h-[298px] bg-white mb-11 mt-11 md:mb-0 " />
            <div className="w-full md:w-[40%] lg:w-full flex flex-col items-center justify-center">
              <p className="text-base leading-6 font-normal tracking-[0.135em] text-white text-center w-[230px] mb-[29px]">
                Don’t have An account on
                <span className="txt-gradient-yellow"> ThunderStake?</span>
              </p>

              <Link to="/signup">
                <Button text="SIGN UP" width="w-[171px]" />
              </Link> 
            </div> */}
          </div>
        </div>
      </div>
      {forgotPopup && (
        <ForgotPwd
          onClose={toggleForgotPwd}
          setSession={setSession}
          username={username}
          setUsername={setUsername}
        />
      )}
      {pwdPopup && (
        <ChangePassword
          onClose={toggleChangePwd}
          username={username}
          session={session}
        />
      )}
      {twoFactor && (
        <TwoFactorAuth
          state={state}
          session={session}
          check={check}
          onClose={closeTwoFactor}
          setSession={setSession}
          navigate={navigate}
          saveUserData={saveUserData}
          setUser={setUser}
          setIsLoggedIn={setIsLoggedIn}
        />
      )}
    </Layout>
  );
}
